// about.scss
@import "../config/veriabals.scss";
@import "../config/mixin.scss";
@import "../config/fuctions.scss";

.aboutUs1 {
  &__info {
    @include flex(space-between, center, 2rem);

    // padding: 2rem;
    margin: 5rem 0;
    border-radius: 10px;

    &__content {
      h6 {
        margin-bottom: 1rem;
        color: $pry-color;
        font-size: 16px;
      }

      h2 {
        font-size: 2.5rem;
        font-weight: 600;
        z-index: 1;
        display: inline-block;
        position: relative;
        margin-bottom: 2rem;
        color: $pry-color;


      }

      p {
        font-size: 16px;
        margin-bottom: 2rem;
        font-weight: 500;
      }

      &__ctaButton {
        background-color: transparent;
        border: none;
        color: $pry-color;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 600;
        margin-right: 1rem;

        &:hover {
          &::after {
            position: relative;

            width: 10px;
            height: 4px;
            content: "";
            border-radius: 10px;
            background-color: $pry-color;
          }
        }
      }
    }

    img {
      width: 50%;
      height: 100%;
    }

  }
}

.services {
  padding: 2rem 0 5rem;

  .head {
    @include head;

    h1 {
      font-size: 56px;
      font-weight: 400;
      color: $pry-color;
    }

    p {
      margin: 1rem 5rem;
      font-size: 16px
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;


    // &__serviceItem {
    //     // background: #f9f9f9;
    //     cursor: pointer;
    //     padding: 1rem;
    //     border-radius: 10px;
    //     // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    //     border: 2px solid transparent;
    //     transition: all 0.6s ease-in-out;

    //     &:hover {
    //         border: 2px solid $pry-color;
    //         border-radius: 10px;
    //         padding: 1rem;

    //         h4 {
    //             color: $white-color;
    //             background-color: $pry-color;
    //             transition: all 0.6s ease-in-out;

    //         }
    //     }

    //     h4 {
    //         background-color: $light-color;
    //         display: inline-block;
    //         padding: 0.5rem 1rem;
    //         border-radius: 5px;
    //         margin-bottom: 1.3rem;
    //         color: $pry-color;
    //     }

    //     h3 {
    //         font-size: 1.2rem;
    //         font-weight: 600;
    //         margin-bottom: 1rem;
    //         color: #2f2f2f;
    //         padding-top: 10px;
    //     }

    //     p {
    //         font-size: 14px;
    //         font-weight: 500;
    //         color: #777;
    //     }
    // }

    &__serviceItem {
      box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
      border-bottom-right-radius: 50px;
      border-top-left-radius: 50px;
      cursor: pointer;
      transition: all 0.6s ease-in-out;
      width: 330px;

      .imge {
        position: relative;

        img {
          width: 100%;
          height: 40vh;
          object-fit: cover;
          border-top-left-radius: 50px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 8px;
          /* Adjust the border thickness */
          background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
          /* Match the border curve of the image */
          pointer-events: none;
          /* Ensure it doesn’t block interactions */
        }
      }


      .content {
        padding: 2rem;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
        color: $pry-color;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #464646;
      }
    }
  }
}


@media screen and (max-width:1199px) {
  .aboutUs1 {
    &__info {
      @include flex(space-between, center, 2rem);

      // padding: 2rem;
      margin: 3rem 0;
      border-radius: 10px;

      &__content {


        h2 {
          font-size: 2.5rem;
          font-weight: 500;
          z-index: 1;
          display: inline-block;
          position: relative;
          margin-bottom: 2rem;
          color: $pry-color;


        }

        p {
          font-size: 14px;
          margin-bottom: 2rem;
          font-weight: 500;
        }

        &__ctaButton {
          background-color: transparent;
          border: none;
          color: $pry-color;
          margin-bottom: 1.5rem;
          font-size: 1.2rem;
          cursor: pointer;
          border-radius: 4px;
          font-weight: 600;
          margin-right: 1rem;

          &:hover {
            &::after {
              position: relative;

              width: 10px;
              height: 4px;
              content: "";
              border-radius: 10px;
              background-color: $pry-color;
            }
          }
        }
      }

      img {
        width: 50%;
        height: 100%;
      }

    }
  }

  .services {
    padding: 2rem 0 5rem;

    .head {
      @include head;

      h1 {
        font-size: 36px;
        font-weight: 400;
        color: $pry-color;
      }

      p {
        margin: 1rem 5rem;
        font-size: 14px
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 2rem;
      gap: 1rem;


      &__serviceItem {
        box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
        cursor: pointer;
        transition: all 0.6s ease-in-out;
        width: 100%;

        .imge {
          position: relative;

          img {
            width: 100%;
            height: 40vh;
            object-fit: cover;
            border-top-left-radius: 50px;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8px;
            /* Adjust the border thickness */
            background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
            /* Match the border curve of the image */
            pointer-events: none;
            /* Ensure it doesn’t block interactions */
          }
        }


        .content {
          padding: 1rem;
        }

        h3 {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #464646;
        }
      }
    }
  }
}

@media screen and (max-width:424px) {
  .aboutUs1 {
    &__info {
      @include flex(space-between, center, 1rem);
      flex-direction: column;

      // padding: 2rem;
      margin: 3rem 0;
      border-radius: 10px;

      &__content {
        h6 {
          margin-bottom: 1rem;
          color: $pry-color;
          font-size: 16px;
        }

        h2 {
          font-size: 26px;
          font-weight: 500;
          z-index: 1;
          display: inline-block;
          position: relative;
          margin-bottom: 2rem;
          color: $pry-color;


        }

        p {
          font-size: 12px;
          margin-bottom: 2rem;
          font-weight: 500;
        }

        &__ctaButton {
          background-color: transparent;
          border: none;
          color: $pry-color;
          margin-bottom: 1.5rem;
          font-size: 1.2rem;
          cursor: pointer;
          border-radius: 4px;
          font-weight: 600;
          margin-right: 1rem;

          &:hover {
            &::after {
              position: relative;

              width: 10px;
              height: 4px;
              content: "";
              border-radius: 10px;
              background-color: $pry-color;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

    }
  }

  .services {
    padding: 2rem 0 5rem;

    .head {
      @include head;

      h1 {
        font-size: 26px;
        font-weight: 400;
        color: $pry-color;
      }

      p {
        margin: 1rem 0;
        font-size: 12px
      }
    }

    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      &__serviceItem {
        box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
        cursor: pointer;
        transition: all 0.6s ease-in-out;
        width: 330px;

        .imge {
          position: relative;

          img {
            width: 100%;
            height: 40vh;
            object-fit: cover;
            border-top-left-radius: 50px;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 8px;
            /* Adjust the border thickness */
            background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
            /* Match the border curve of the image */
            pointer-events: none;
            /* Ensure it doesn’t block interactions */
          }
        }


        .content {
          padding: 1rem;
        }

        h3 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 1rem;
          color: $pry-color;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          color: #464646;
        }
      }
    }
  }
}