@import "../config/mixin.scss";
@import "../config/veriabals.scss";
@import "../config/fuctions.scss";


.servicePage {
    font-family: 'Arial', sans-serif;

    .headerSection {
        text-align: center;
        // background:linear-gradient(to left, #68ca4813 0%, #0083bf16 46.77%, #0083bf10 100%);
        // background:linear-gradient(to left, #68ca482d 0%, #0083bf21 46.77%, #0083bf1f 100%), url("https://img.freepik.com/free-vector/gradient-hexagonal-background_23-2148939400.jpg?t=st=1731824982~exp=1731828582~hmac=d5dccb8b2138c2070401fac1775c53783b0c16151dd9ba8aaaf6bd005c9f48b9&w=826");
        color: $black-color;
        background: linear-gradient(86deg, rgba(255, 255, 255, 0.589) 34%, rgba(245, 247, 250, 0.461) 74%), url("../../../../public/assets/bg.webp");

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 10rem 0 5rem;
        object-fit: contain;



        .headerButton {
            @include button;
        }

        h1 {
            font-size: 3.5rem;
            margin-bottom: 1rem;
            display: inline-block;
            z-index: 1;
            color: $pry-color;
            position: relative;

        }

        p {
            font-size: 16px;
            font-weight: 500;
            margin: 0 15rem;
            line-height: 1.6;
            color: #4a4949;
            // width: 50%;
            margin-bottom: 2rem;
        }
    }

    /* Service Section */
    .serviceSection {
        padding: 50px 0;
        background-color: #fff;


        .sectionContent {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            gap: 40px;
            margin-bottom: 50px;



            .textContent {
                flex: 1;
                max-width: 50%;

                h2 {
                    font-size: 2.5rem;
                    font-weight: 500;
                    z-index: 1;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 2rem;
                    color: $pry-color;

                    // &::after {
                    //     background-color: $pry-color;
                    //     position: absolute;
                    //     right: -10px;
                    //     bottom: -5px;
                    //     width: 250px;
                    //     height: 30px;
                    //     content: "";
                    //     z-index: -1;

                    // }
                }

                p {
                    font-size: 16px;
                    line-height: 1.6;
                    margin-bottom: 25px;
                }

                .featuresList {
                    list-style: none;
                    padding: 0;
                    margin-bottom: 20px;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);

                    li {
                        font-size: 14px;
                        margin-bottom: 10px;
                        position: relative;
                        font-weight: 500;

                        img {
                            margin-right: 1rem;
                            width: 8%;

                        }

                    }
                }

                .moreInfoButton {
                    @include button;
                    // background-color: $pry-color;
                }
            }

            .imageContent {
                flex: 1;
                text-align: center;

                img {
                    max-width: 100%;
                    height: 80vh;
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    width: 100%;
                    border-top-left-radius: 50px;
                    border-bottom-right-radius: 50px;
                    object-fit: cover;
                }
            }
        }

        &:nth-child(odd) {
            .sectionContent {
                flex-direction: row-reverse; // Reverse the order for even items
            }
        }

    }
}

@media screen and (max-width:1199px) {
    .servicePage {
        font-family: 'Arial', sans-serif;
    
        .headerSection {
            text-align: center;
            color: $black-color;
            background: linear-gradient(86deg, rgba(255, 255, 255, 0.589) 34%, rgba(245, 247, 250, 0.461) 74%), url("../../../../public/assets/bg.webp");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 8rem 0 3rem;
            object-fit: contain;
            .headerButton {
                @include button;
                padding: 0.5rem 1.5rem;
                font-size: 14px;
            }
    
            h1 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
                display: inline-block;
                z-index: 1;
                color: $pry-color;
                position: relative;
    
            }
    
            p {
                font-size: 14px;
                font-weight: 500;
                margin: 0 15rem;
                line-height: 1.6;
                color: #4a4949;
                // width: 50%;
                margin-bottom: 2rem;
            }
        }
    
        /* Service Section */
        .serviceSection {
            padding: 50px 0;
            background-color: #fff;
            .sectionContent {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: row;
                gap: 40px;
                margin-bottom: 30px;

                .textContent {
                    flex: 1;
                    max-width: 60%;
    
                    h2 {
                        font-size: 2rem;
                        font-weight: 500;
                        z-index: 1;
                        display: inline-block;
                        position: relative;
                        margin-bottom: 2rem;
                        color: $pry-color;
    
                    }
    
                    p {
                        font-size: 14px;
                        line-height: 1.6;
                        margin-bottom: 25px;
                    }
    
                    .featuresList {
                        list-style: none;
                        padding: 0;
                        margin-bottom: 10px;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
    
                        li {
                            font-size: 12px;
                            margin-bottom: 10px;
                            position: relative;
                            font-weight: 500;
    
                            img {
                                margin-right: 1rem;
                                width: 5%;
    
                            }
    
                        }
                    }
    
                    .moreInfoButton {
                        @include button;
                        font-size: 14px;
                        padding: 0.5rem 1.5rem;
                        // background-color: $pry-color;
                    }
                }
    
                .imageContent {
                    flex: 1;
                    text-align: center;
    
                    img {
                        max-width: 100%;
                        height: 80vh;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                        width: 100%;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                        object-fit: cover;
                    }
                }
            }
    
            &:nth-child(odd) {
                .sectionContent {
                    flex-direction: row-reverse; // Reverse the order for even items
                }
            }
    
        }
    }
}

@media screen and (max-width:424px) {
    .servicePage {
        font-family: 'Arial', sans-serif;

        .headerSection {
            text-align: center;
            color: $black-color;

            padding: 3rem 0;
            object-fit: contain;



            .headerButton {
                @include button;
                font-size: 14px;
                padding: 0.5rem 1rem;
            }

            h1 {
                font-size: 26px;
                margin-bottom: 1rem;
                display: inline-block;
                z-index: 1;
                color: $pry-color;
                position: relative;

            }

            p {
                font-size: 12px;
                font-weight: 500;
                margin: 0;
                line-height: 1.6;
                color: #4a4949;
                // width: 50%;
                margin-bottom: 2rem;
            }
        }

        /* Service Section */
        .serviceSection {
            padding: 20px 0;
            background-color: #fff;


            .sectionContent {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column-reverse;
                gap: 10px;
                margin-bottom: 0;



                .textContent {
                    max-width: 100%;

                    h2 {
                        font-size: 20px;
                        font-weight: 500;
                        z-index: 1;
                        display: inline-block;
                        position: relative;
                        margin-bottom: 1rem;
                        color: $pry-color;

                        
                    }

                    p {
                        font-size: 12px;
                        line-height: 1.6;
                        margin-bottom: 25px;
                    }

                    .featuresList {
                        list-style: none;
                        padding: 0;
                        margin-bottom: 20px;
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);

                        li {
                            font-size: 12px;
                            margin-bottom: 10px;
                            position: relative;
                            font-weight: 500;

                            img {
                                margin-right: 0.5rem;
                                width: 6%;

                            }

                        }
                    }

                    .moreInfoButton {
                        @include button;
                        font-size: 14px;
                        padding: 0.5rem 1rem;
                        // background-color: $pry-color;
                    }
                }

                .imageContent {

                    img {
                        height: 50vh;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                        width: 100%;
                        border-top-left-radius: 50px;
                        border-bottom-right-radius: 50px;
                        object-fit: cover;
                    }
                }
            }

            &:nth-child(odd) {
                .sectionContent {
                    flex-direction: column-reverse; // Reverse the order for even items
                }
            }

        }
    }
}