@import "../config/veriabals.scss"; 
@import "../config/mixin.scss"; 
@import "../config/fuctions.scss"; 

.heading {
    background: linear-gradient(86deg, rgba(221, 241, 247, 0.721) 34%, rgba(241, 254, 245, 0.703) 74%), url("../../../../public/assets/bg.webp");
    color: $black-color;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 10rem 0 5rem;
    text-align: center;
  
    h1 {
        font-size: 3.5rem;
        margin-bottom: 2rem;
        display: inline-block;
        z-index: 1;
        position: relative;
        color: $pry-color;
        // text-align: ;
  
        // &::after {
        //     background-color: #55cdb9;
        //     position: absolute;
        //     // top: 0;
        //     right: -10px;
        //     bottom: -10px;
        //     width: 200px;
        //     height: 30px;
        //     content: "";
        //     z-index: -1;
  
        // }
    }
  
    p {
        font-size: 16px;
        font-weight: 500;
        // margin: 0 3rem;
        line-height: 1.6;
        color: #4d4d4d;
        width: 70%;
    }
  }

  @media screen and (max-width:424px) {
    .heading {
        padding: 3rem 0 ;

        h1 {
            font-size: 26px;
            margin-bottom: 1rem;   
        }

        p {
            font-size: 12px;

        }
    }
  }