@import "../config/veriabals.scss";
@import "../config/mixin.scss";
@import "../config/fuctions.scss";

// Banner Container
.banner {
    padding: 150px 0 5rem;
    background:linear-gradient(86deg, rgba(255, 255, 255, 0.721) 34%, rgba(255, 255, 255, 0.703) 74%), url("../../../../public/assets/bg.webp");
    background-size: contain;
    z-index: 9999;
    &__content {
        display: grid;
        grid-template-columns: 2fr 1.5fr;
        align-items: flex-start;
        gap: 2rem;
    }

    &__wrp__content {
        h1 {
            font-size: 3rem;
            // font-weight: bold;
            font-weight: 600;
            margin-bottom: 10px;
            color: $pry-color;
        }

        p {
            font-size: 1.1rem;
            font-weight: 500;
            color: #414141;
            margin-bottom: 2rem;
            margin-top: 2rem;
        }

        &__ctaButton {
            @include button;
            font-weight: 600;

        }
    }

    &__largeImage {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
}


.services {
    padding: 5rem 0;
    position: relative;

    .head {
        @include head;
        h2{
            font-size: 56px;
            font-weight: 400;
            color: $pry-color;
            &::after{
                display: none;
            }
        }
        p{
            margin:0 10rem ;
            font-size: 18px
        }
        button{
            @include button;
            background-color: $pry-color;
            margin-top: 2rem;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
        column-gap: 2rem;
        margin:  0 2rem;
        
        &__serviceItem {
            background: #000;
            height: 400px;
            margin: 0;
            background: $white-color;
            position: relative;
            box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
            border-bottom-right-radius: 50px;
            border-top-left-radius: 50px;
        
            .image_wrapper {
                position: relative;
                display: inline-block; /* Or use block depending on your layout */
                width: 100%;
                height: 200px;
                border-bottom-right-radius: 50px;
                border-top-left-radius: 50px;
                overflow: hidden; /* Ensures the pseudo-element doesn't exceed the bounds */
            }
        
            .image_wrapper img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-bottom-right-radius: 50px;
                border-top-left-radius: 50px; /* Add this to match the wrapper */
            }
        
            .image_wrapper::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 5px; /* Adjust the border thickness */
                background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
                border-bottom-right-radius: 50px;
                border-top-left-radius: 50px; /* Match the border curve of the image */
                pointer-events: none; /* Ensure it doesn’t block interactions */
            }
        
            &__content {
                padding: 1.5rem;
                padding-top: 1rem;
        
                button {
                    border: none;
                    background-color: transparent;
                    color: $pry-color;
                    font-weight: 600;
                    margin-top: 1.5rem;
                    font-size: 16px;
                    transition: all 1s ease-in-out;
        
                    i {
                        color: #000;
                    }
        
                    svg {
                        margin-left: 0;
                        transition: all 0.6s ease-in-out;
                        fill: $pry-color;
                    }
        
                    &:hover {
                        svg {
                            transition: all 0.6s ease-in-out;
                            display: inline-block;
                            margin-left: 1.5rem;
                            transform: translateX(50%);
                        }
                    }
                }
        
                h3 {
                    font-size: 1.1rem;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    color: $black-color;
                }
        
                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: #0f0f0f;
                }
            }
        }
        
    }
}

.solutions {
    padding: 5rem 0 8rem; 
    position: relative;
    .head {
        @include head;
        h2{
            font-size: 56px;
            font-weight: 400;
            color: $pry-color;
            &::after{
                display: none;
            }
        }
        p{
            margin:0 10rem ;
            font-size: 18px;
            color: #3b3b3b
        }
       
    }

    &__grid {
        @include flex(none, center, 5rem);
        flex-direction: column;
        margin:  0 2.5rem;
        &__solutionItem {
            @include flex(none, flex-start, 2rem);
            gap: 4rem;

            img {
                width:45%;
                border-top-right-radius: 50px;
                border-bottom-left-radius: 50px;

            }

            &__info_solution {
                h3 {
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 28px*1.5;
                    z-index: 1;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 2rem;
                    color: $pry-color;
                   

                }
                p{
                    font-size: 16px;
                    color: #000000;
                    font-weight: 500;
                }
            }
        }

        &__solutionItem:nth-child(2) {
            flex-direction: row-reverse;
        }
    }
}



.aboutUs {
    &__info {
        // background:linear-gradient(to left, #68ca483e 0%, #0083bf49 46.77%, #00477644 100%);
        @include flex(space-between, center, 2rem);
        color: $white-color;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 2rem;
        border-radius: 10px;

        &__content {
            h6 {
                margin-bottom: 1rem;
                color: $pry-color;
                font-size: 16px;
                display: none;
            }

            h2 {
                font-size: 3.5rem;
                font-weight: 400;
                z-index: 1;
                display: inline-block;
                position: relative;
                margin-bottom: 2rem;
                color: $pry-color;

                &::after {
                    // background-color: #55cdb9;
                    position: absolute;
                    right: -10px;
                    bottom: -5px;
                    width: 170px;
                    height: 30px;
                    content: "";
                    z-index: -1;

                }
            }

            p {
                font-size: 16px;
                margin-bottom: 2rem;
                font-weight: 500;
                color: #3a3a3a
            }

            &__ctaButton {
                @include button;
            }
        }

        img {
            border-radius: 20px;
            width: 50%;
            background:linear-gradient(to left, #68ca483e 0%, #0083bf49 46.77%, #00477600 100%);
            padding: 2rem 2rem 3rem 0;
            // border-top-right-radius:100px ;
        
        }
    }
}

@media screen and (max-width: 1199px) {
    .banner {
        padding: 150px 0 5rem;
        background:linear-gradient(86deg, rgba(255, 255, 255, 0.721) 34%, rgba(255, 255, 255, 0.703) 74%), url("../../../../public/assets/bg.webp");
        background-size: contain;
        z-index: 9999;
        &__content {
            display: grid;
            grid-template-columns: 2fr 1.5fr;
            align-items: flex-start;
            gap: 2rem;
        }
    
        &__wrp__content {
            h1 {
                font-size: 2.5rem;
                // font-weight: bold;
                font-weight: 600;
                margin-bottom: 10px;
                color: $pry-color;
            }
    
            p {
                font-size: 14px;
                font-weight: 500;
                color: #414141;
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
    
            &__ctaButton {
                @include button;
                font-weight: 600;
                font-size: 14px;
    
            }
        }
    
        &__largeImage {
    
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
        }
    }
    
    
    .services {
        padding: 5rem 0;
        position: relative;
    
        .head {
            @include head;
            h2{
                font-size: 36px;
                font-weight: 400;
                color: $pry-color;
                &::after{
                    display: none;
                }
            }
            p{
                margin:0 10rem ;
                font-size: 14px
            }
            button{
                @include button;
                background-color: $pry-color;
                margin-top: 2rem;
            }
        }
    
        &__grid {
            grid-template-columns: repeat(2, 1fr);  
        }
    }
    
    .solutions {
        padding: 3rem 0 5rem; 
        position: relative;
        .head {
            @include head;
            h2{
                font-size: 36px;
                font-weight: 400;
                color: $pry-color;
                &::after{
                    display: none;
                }
            }
            p{
                margin:0 10rem ;
                font-size: 14px;
                color: #3b3b3b
            }
           
        }
    
        &__grid {
            @include flex(none, center, 2rem);
            flex-direction: column;
            margin:  0 2.5rem;
            &__solutionItem {
                @include flex(none, flex-start, 2rem);
                gap: 4rem;
    
                img {
                    width:45%;
                    border-top-right-radius: 50px;
                    border-bottom-left-radius: 50px;
    
                }
    
                &__info_solution {
                    h3 {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 24px*1.5;
                        z-index: 1;
                        display: inline-block;
                        position: relative;
                        margin-bottom: 2rem;
                        color: $pry-color;
                       
    
                    }
                    p{
                        font-size: 14px;
                        color: #3b3b3b;
                        font-weight: 500;
                    }
                }
            }
    
            &__solutionItem:nth-child(2) {
                flex-direction: row-reverse;
            }
        }
    }
 
    .aboutUs {
        &__info {
            // background:linear-gradient(to left, #68ca483e 0%, #0083bf49 46.77%, #00477644 100%);
            @include flex(space-between, center, 2rem);
            color: $white-color;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 2rem;
            border-radius: 10px;
    
            &__content {
                h6 {
                    margin-bottom: 1rem;
                    color: $pry-color;
                    font-size: 16px;
                    display: none;
                }
    
                h2 {
                    font-size: 36px;
                    font-weight: 400;
                    z-index: 1;
                    display: inline-block;
                    position: relative;
                    margin-bottom: 2rem;
                    color: $pry-color;
    
                 
                }
    
                p {
                    font-size: 14px;
                    margin-bottom: 2rem;
                    font-weight: 500;
                    color: #3a3a3a
                }
    
                &__ctaButton {
                    @include button;
                    font-size: 14px;
                    padding: 0.5rem 2rem;
                }
            }
    
            img {
                border-radius: 20px;
                width: 50%;
                background:linear-gradient(to left, #68ca483e 0%, #0083bf49 46.77%, #00477600 100%);
                padding: 2rem 2rem 3rem 0;
                // border-top-right-radius:100px ;
            
            }
        }
    }
}


@media screen and (max-width:567px) {
    .banner {
        padding: 50px 0 0;
        &__content {
            grid-template-columns: 1fr;
        }
    
        &__wrp__content {
            h1 {
                font-size: 2rem;
                // font-weight: bold;
                font-weight: 600;
                margin-bottom: 10px;
                color: $pry-color;
            }
    
            p {
                font-size: 12px;
            }
    
            &__ctaButton {
                @include button;
                font-weight: 600;
                font-size: 14px;
                padding: 0.5rem 1rem;
    
            }
        }
    
        &__largeImage {
    
            img {
                width: 80%;
                height: 100%;
                display: none;
                object-fit: cover;
            }
    
        }
    }
    .services {
        padding: 3rem 0;
        position: relative;
    
        .head {
            @include head;
            h2{
                font-size: 26px;
                font-weight: 400;
                color: $pry-color;
                &::after{
                    display: none;
                }
            }
            p{
                margin:0 0 ;
                font-size: 12px
            }
            button{
                @include button;
                background-color: $pry-color;
                margin-top: 2rem;
            }
        }
    
        &__grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem;
            column-gap: 2rem;
            margin:  0 ;
            
            &__serviceItem {
                background: #000;
                height: 350px;
                margin: 0;
                background: $white-color;
                position: relative;
                box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
                border-bottom-right-radius: 50px;
                border-top-left-radius: 50px;
            
             
            
                &__content {
                    padding: 1.5rem;
                    padding-top: 1rem;
            
                    button {
                        border: none;
                        background-color: transparent;
                        color: $pry-color;
                        font-weight: 600;
                        margin-top: 1rem;
                        font-size: 14px;
                        transition: all 1s ease-in-out;
            
                        i {
                            color: #000;
                            font-size: 14px;
                        }
            
                        svg {
                            margin-left: 0;
                            transition: all 0.6s ease-in-out;
                            fill: $pry-color;
                        }
            
                        &:hover {
                            svg {
                                transition: all 0.6s ease-in-out;
                                display: inline-block;
                                margin-left: 1.5rem;
                                transform: translateX(50%);
                            }
                        }
                    }
            
                    h3 {
                        font-size: 1rem;
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                        color: $black-color;
                    }
            
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #0f0f0f;
                    }
                }
            }
            
        }
    }
    
    .solutions {
        padding: 5rem 0 8rem; 
        position: relative;
        .head {
            @include head;
            h2{
                font-size: 26px;
                font-weight: 400;
                color: $pry-color;
                &::after{
                    display: none;
                }
            }
            p{
                margin:0 0rem ;
                font-size: 12px;
                color: #3b3b3b
            }
           
        }
    
        &__grid {
            @include flex(none, center, 5rem);
            flex-direction: column;
            margin:  0 ;
            &__solutionItem {
                @include flex(none, flex-start, 2rem);
                flex-direction: column;
                gap: 4rem;
    
                img {
                    width:100%;
                    border-top-right-radius: 50px;
                    border-bottom-left-radius: 50px;
    
                }
    
                &__info_solution {
                    h3 {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 20px*1.5;
                        z-index: 1;
                        display: inline-block;
                        position: relative;
                        margin-bottom: 1rem;
                        color: $pry-color;
                       
    
                    }
                    p{
                        font-size: 12px;
                        color: #000000;
                        font-weight: 500;
                    }
                }
            }
    
            &__solutionItem:nth-child(2) {
                flex-direction: column;
            }
        }
    }
    .aboutUs {
        &__info {
            // background:linear-gradient(to left, #68ca483e 0%, #0083bf49 46.77%, #00477644 100%);
            @include flex(space-between, center, 1rem);
            flex-direction: column-reverse;
            padding: 2rem 0;
            
    
            &__content {
    
                h2 {
                    font-size: 26px;
                    margin-bottom: 1rem;
                }
    
                p {
                    font-size: 12px;
                    margin-bottom: 1.5rem
                }
    
                &__ctaButton {
                    @include button;
                    padding: 0.5rem 1rem;
                    font-size: 14px;
                    margin:0;
                }
            }
    
            img {
                border-radius: 20px;
                width: 100%;
                background:linear-gradient(to bottom, #68ca483e 0%, #0083bf49 46.77%, #00477600 100%);
                padding: 1.5rem;
                // border-top-right-radius:100px ;
            
            }
        }
    }
}