@import "../config/veriabals.scss";
@import "../config/mixin.scss";
@import "../config/fuctions.scss";

.heading {
  background: linear-gradient(86deg, rgba(221, 241, 247, 0.721) 34%, rgba(241, 254, 245, 0.703) 74%), url("../../../../public/assets/bg.webp");
  color: $black-color;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 10rem 0 5rem;

  h1 {
      font-size: 3.5rem;
      margin-bottom: 2rem;
      display: inline-block;
      z-index: 1;
      position: relative;
      color: $pry-color;
      // text-align: ;

      // &::after {
      //     background-color: #55cdb9;
      //     position: absolute;
      //     // top: 0;
      //     right: -10px;
      //     bottom: -10px;
      //     width: 200px;
      //     height: 30px;
      //     content: "";
      //     z-index: -1;

      // }
  }

  p {
      font-size: 16px;
      font-weight: 500;
      // margin: 0 3rem;
      line-height: 1.6;
      color: #4d4d4d;
      width: 70%;
  }
}

.aboutUs1 {
  &__info {
    margin: 5rem 0;
  // background: rgba(231, 254, 238, 0.721);

    // background: #02ad9030;
    @include flex(space-between, flex-start, 1rem);
    // padding: 2rem;
    border-radius: 10px;
    flex-direction: row; // Align items horizontally


    h2 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      font-weight: bold;
      color: #333;
      text-transform: capitalize;
    }
    
    h2 {
      font-size: 2rem;
      margin-bottom: 2rem;
      color: $pry-color;
      font-weight: 500;
      text-transform: capitalize;
    }
    
    p {
      font-size: 16px;
      // margin-bottom: 2rem;
      color: #fdfdfd;
    }
    &__content {

      &__serviceItem {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        border-radius: 10px;
        border: 2px solid transparent;
        transition: all 0.6s ease-in-out;

        h4 {
          margin-right: 1rem;
          font-size: 2.1rem;
          background-color: $light-color;
          padding: 0.5rem 1rem;
          border-radius: 5px;
          color: $pry-color;
      text-transform: capitalize;

        }

        h3 {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          color: #2f2f2f;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #202020;
          text-align: start;
        }

        &:hover {
          border: 2px solid $pry-color;
        }
      }
    }
  }

  // Contact Form styles


}
.head{
  margin: 2rem 0;
  @include head;
}
// Contact Form styles
.contactForm {
  // Light background for contrast
  // padding: 2rem;
  // max-width: 600px;
  margin: 0 auto; // Center the form



  .parentformField {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; // Add spacing between input fields
    justify-content: space-between;

    .formField {
      margin-bottom: 1rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        color: #333;
        font-weight: 600;
      }

      input,
      textarea {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;

        border: 1px solid #ccc;

        // background-color: #f5f5f5; // Subtle background for input
        transition: border-color 0.3s;

        &:hover {
          border-color: #55cdb9; // Light border color on hover
        }


      }

      textarea {
        resize: vertical;
        min-height: 150px;
      }
    }
  }

  button {
    width: 100%;
    background-color: $pry-color;
    color: white;
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
      background-color: darken(#55cdb9, 10%);
      transform: translateY(-3px); // Slight lift on hover
    }

    &:focus {
      outline: none;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    }
  }

  @media (max-width: 768px) {
    .parentformField {
      flex-direction: column; // Stack fields on smaller screens
    }

    .formField {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
}

@media screen and (max-width:1199px) {
  .aboutUs1 {
    &__info {
      margin: 5rem 0;
      @include flex(space-between, flex-start, 1rem);
      border-radius: 10px;
      flex-direction: row; 
  
  
      h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        font-weight: bold;
        color: #333;
        text-transform: capitalize;
      }
      
      h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
        color: $pry-color;
        font-weight: 500;
        text-transform: capitalize;
      }
      
      p {
        font-size: 16px;
        // margin-bottom: 2rem;
        color: #fdfdfd;
      }
      &__content {
  
        &__serviceItem {
          flex: 1 1 calc(25% - 1rem);
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          padding: 0.5rem;
          border-radius: 10px;
          border: 2px solid transparent;
          transition: all 0.6s ease-in-out;
  
          h4 {
            margin-right: 1rem;
            font-size: 2.1rem;
            background-color: $light-color;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            color: $pry-color;
        text-transform: capitalize;
  
          }
  
          h3 {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: #2f2f2f;
          }
  
          p {
            font-size: 14px;
            font-weight: 500;
            color: #202020;
            text-align: start;
          }
  
          &:hover {
            border: 2px solid $pry-color;
          }
        }
      }
    }
  
    // Contact Form styles
  
  
  }
  .head{
    margin: 2rem 0;
    @include head;
  }
  // Contact Form styles
  .contactForm {
    // Light background for contrast
    // padding: 2rem;
    // max-width: 600px;
    margin: 0 auto; // Center the form
  
  
  
    .parentformField {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem; // Add spacing between input fields
      justify-content: space-between;
  
      .formField {
        flex: 1 1 calc(30% - 1rem); // 2-column layout for larger screens
        margin-bottom: 1rem;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: 1.1rem;
          color: #333;
          font-weight: 600;
        }
  
        input,
        textarea {
          width: 100%;
          padding: 0.75rem;
          font-size: 1rem;
  
          border: 1px solid #ccc;
  
          // background-color: #f5f5f5; // Subtle background for input
          transition: border-color 0.3s;
  
          &:hover {
            border-color: #55cdb9; // Light border color on hover
          }
  
  
        }
  
        textarea {
          resize: vertical;
          min-height: 150px;
        }
      }
    }
  
    button {
      width: 100%;
      background-color: $pry-color;
      color: white;
      font-size: 1.2rem;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;
  
      &:hover {
        background-color: darken(#55cdb9, 10%);
        transform: translateY(-3px); // Slight lift on hover
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      }
    }
  
    @media (max-width: 768px) {
      .parentformField {
        flex-direction: column; // Stack fields on smaller screens
      }
  
      .formField {
        width: 100%;
      }
  
      button {
        width: 100%;
      }
    }
  }
}


@media screen and (max-width:567px) {
  .heading {
    background: linear-gradient(86deg, rgba(221, 241, 247, 0.721) 34%, rgba(241, 254, 245, 0.703) 74%), url("../../../../public/assets/bg.webp");
    color: $black-color;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 3rem 0 ;
  
    h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        display: inline-block;
        z-index: 1;
        position: relative;
        color: $pry-color;
      
    }
  
    p {
        font-size: 12px;
        font-weight: 500;
        // margin: 0 3rem;
        line-height: 1.6;
        color: #4d4d4d;
        width: 100%;
    }
  }
  .aboutUs1 {
    &__info {
      margin: 5rem 0;
      @include flex(space-between, flex-start, 1rem);
      flex-direction: column;
      border-radius: 10px;  
  

      
      h2 {
        font-size: 1rem;
        margin-bottom: 2rem;
        color: $pry-color;
        font-weight: 500;
        text-transform: capitalize;
      }
      
      p {
        font-size: 16px;
        // margin-bottom: 2rem;
        color: #fdfdfd;
      }
      &__content {
  
        &__serviceItem {
          margin-bottom: 1rem;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 0.5rem;
          border-radius: 10px;
          border: 2px solid transparent;
          transition: all 0.6s ease-in-out;
  
          h4 {
            margin-right: 1rem;
            font-size: 1rem;
            background-color: $light-color;
            padding: 0.5rem 1rem;
            border-radius: 5px;
            color: $pry-color;
            text-transform: capitalize;
            margin-bottom: 1rem;
  
          }
  
          h3 {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
            color: #2f2f2f;
          }
  
          p {
            font-size: 12px;
            font-weight: 500;
            color: #202020;
            text-align: start;
          }
  
          &:hover {
            border: 2px solid $pry-color;
          }
        }
      }
    }
  
    // Contact Form styles
  
  
  }
  .head{
    margin: 1rem 0;
    @include head;
  }
  // Contact Form styles
  .contactForm {
    margin: 0 auto; // Center the form
    .parentformField {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem; // Add spacing between input fields
      justify-content: space-between;
  
      .formField {
        margin-bottom: 0;
        width: 100%;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: 14px;
          color: #333;
          font-weight: 600;
        }
  
        input,
        textarea {
          width: 100%;
          padding: 0.75rem;
          font-size: 1rem;
  
          border: 1px solid #ccc;
  
          // background-color: #f5f5f5; // Subtle background for input
          transition: border-color 0.3s;
  
          &:hover {
            border-color: #55cdb9; // Light border color on hover
          }
  
  
        }
  
        textarea {
          resize: vertical;
          min-height: 150px;
        }
      }
    }
  
    button {
      width: 100%;
      background-color: $pry-color;
      color: white;
      font-size: 1.2rem;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s, transform 0.2s;
  
      &:hover {
        background-color: darken(#55cdb9, 10%);
        transform: translateY(-3px); // Slight lift on hover
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      }
    }
  
    @media (max-width: 768px) {
      .parentformField {
        flex-direction: column; // Stack fields on smaller screens
      }
  
      .formField {
        width: 100%;
      }
  
      button {
        width: 100%;
      }
    }
  }
}