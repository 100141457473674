@import "../config/mixin.scss";
@import "../config/veriabals.scss";
@import "../config/fuctions.scss";

.scrolled {
  background: #ffffff !important;
  top: 0;
  box-shadow: $bg-shadow;
  animation: slide 1s ease-in-out;
  // border-bottom:4px solid #004676;
}

@keyframes slide {
  0% {
    top: -100%
  }

  100% {
    top: 0%;

  }
}


.nav {
  background-color: #ffffff;
  transition: all 0.4s ease-in-out;
  position: fixed;
  width: 100%;
  z-index: 9999;
  padding: 0rem 0;
  border-bottom: 1px solid #ccc;
  // background:linear-gradient(86deg, rgba(110, 167, 252, 0.0666666667) 34%, rgba(110, 167, 252, 0.0666666667) 74%),;

  // background: linear-gradient(180deg, rgba(110, 114, 225, 0.822) 29%, rgba(110, 114, 225, 0.822)  89%, rgba(110, 114, 225, 0.822)  100%);

  &bar {
    position: relative;
    @include flex(space-between, center, 0);
    // padding: 10px 0;

    &__logo {
      // @include flex(none, center, 0);

      img {
        height: 80px;
        margin-right: 10px;
      }

      span {
        font-weight: 700;
        font-size: 1.5rem;
      }
    }

    &__menu {
      @include flex(space-between, center, 0);

      &__links {
        @include flex(none, center, 20px);
        margin-right: 3rem;

        li {
          padding-top: 2rem;

          a {
            padding: 0 0.5rem 2rem;
            font-size: 1.1rem;
            color: $pry-color;
            transition: color 0.3s ease;
            font-weight: 700;
            display: flex;
            align-items: center;

            &.active {

              // color:$pry-color !important;
              // // background: $pry-color;
              // padding: 0;
              .dropdownIcon {
                margin-left: 5px;
                font-size: 0.8rem;
                transition: all 0.4s ease-in-out
              }


            }

            &:hover {
              color: $pry-color;

              .dropdownIcon {

                transform: rotate(180deg)
              }
            }
          }



          .dropdownIcon {
            margin-left: 5px;
            font-size: 0.8rem;
            transition: all 0.4s ease-in-out
          }
        }
      }

      &__contact {
        @include flex(none, center, 2rem);
        border-left: 1.5px solid $light-black-color;

        &__phoneIcon {
          margin-left: 3rem;
          font-size: 2rem;
          color: $pry-color;
          // background-color: #00477630;
          // padding: 10px;
          border-radius: 4px;
        }

        &__number {
          display: flex;
          flex-direction: column;

          span {
            font-family: 'Poppins', sans-serif;
            font-size: 0.9rem;
            color: #777;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-size: 1.1rem;
            font-weight: 600;
            color: $pry-color;
            text-decoration: none;
          }
        }

        &__button {
          @include button;
          // background-color:$pry-color;
          // color: $pry-color;
          font-weight: 600;
          // background-color: $pry-color;
          // color: $white-color;
        }
      }
    }
  }

  .topnav {
    background: linear-gradient(190deg, #e2d8ff 15.22%, #fee9ff 67.5%);
    text-align: center;

    p {
      color: $pry-color;
      font-weight: 600;
      padding: 0.5rem 0;

    }
  }

  .dropdown {
    // background: linear-gradient(to left, #f7f9f6d2, #f4f8f9e2, #f6f6f6cd) , url("https://img.freepik.com/free-vector/gradient-white-background-with-hexagons_79603-2164.jpg?t=st=1731848046~exp=1731851646~hmac=17db2cbcfea99c165c0ccb08e787eb94b0aa4f71b1387d208b7cef44f00be4da&w=826"); /* Gradient colors */
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2));
    backdrop-filter: blur(20px);
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    top: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 2;
    padding: 1rem;
    // width: 100%; // Adjust width based on design
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    animation: slides 0.7s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      // bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      /* Thickness of the border */
      background: linear-gradient(to left, #68ca48, #0082bf, #004676);
      /* Gradient colors */

    }

    &__box {

      & a {
        padding: 0px 20px;
        color: #5e5d5d;
        text-decoration: none;
        font-size: 0.9rem;
        border: 2px solid transparent;
        transition: all 0.6s ease inherit;


        &:hover {
          // background-color: $pry-color;
          border: 2px solid $pry-color;
          border-radius: 10px;

        }
      }

      &__item {
        padding: 1rem 0;

        h4 {

          // padding: 0.5rem 1rem;
          border-radius: 5px;
          margin-bottom: 1.3rem;
          color: $pry-color;
          display: inline;
          font-size: 28px;
        }

        &__label {
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: 600;
          margin-top: 1rem;
          color: $black-color;
        }

        &__description {
          font-size: 0.85rem;
          font-weight: 400;
          color: #404040;
          line-height: 1.4;
        }
      }
    }

  }

  @keyframes slides {
    0% {
      top: 70%;
      opacity: 0;
    }

    100% {
      top: 100%;
      opacity: 1;

    }

  }
}

.mobile_nav {
  display: none;
}

.toggle_menu {
  display: none;
}


@media screen and (max-width:1199px) {

  .nav {
    background-color: #ffffff;
    transition: all 0.4s ease-in-out;
    position: fixed;
    width: 100%;
    z-index: 9999;
    padding: 0rem 0;
    border-bottom: 1px solid #ccc;
    // background:linear-gradient(86deg, rgba(110, 167, 252, 0.0666666667) 34%, rgba(110, 167, 252, 0.0666666667) 74%),;

    // background: linear-gradient(180deg, rgba(110, 114, 225, 0.822) 29%, rgba(110, 114, 225, 0.822)  89%, rgba(110, 114, 225, 0.822)  100%);

    &bar {
      position: relative;
      @include flex(space-between, center, 0);
      // padding: 10px 0;

      &__logo {
        // @include flex(none, center, 0);

        img {
          height: 60px;
          margin-right: 10px;
        }

        span {
          font-weight: 700;
          font-size: 1.5rem;
        }
      }

      &__menu {
        @include flex(space-between, center, 0);

        &__links {
          @include flex(none, center, 10px);
          margin-right: 1rem;

          li {
            padding-top: 2rem;

            a {
              padding: 0 0.5rem 2rem;
              font-size: 1rem;
              color: $pry-color;
              transition: color 0.3s ease;
              font-weight: 700;
              display: flex;
              align-items: center;

              &.active {

                // color:$pry-color !important;
                // // background: $pry-color;
                // padding: 0;
                .dropdownIcon {
                  margin-left: 5px;
                  font-size: 0.8rem;
                  transition: all 0.4s ease-in-out
                }


              }

              &:hover {
                color: $pry-color;

                .dropdownIcon {

                  transform: rotate(180deg)
                }
              }
            }



            .dropdownIcon {
              margin-left: 5px;
              font-size: 0.8rem;
              transition: all 0.4s ease-in-out
            }
          }
        }

        &__contact {
          @include flex(none, center, 1rem);
          border-left: 1.5px solid $light-black-color;

          &__phoneIcon {
            margin-left: 3rem;
            font-size: 1.5rem;
            color: $pry-color;
            // background-color: #00477630;
            // padding: 10px;
            border-radius: 4px;
          }

          &__number {
            display: flex;
            flex-direction: column;

            span {
              font-family: 'Poppins', sans-serif;
              font-size: 0.7rem;
              color: #777;
            }

            a {
              font-family: 'Poppins', sans-serif;
              font-size: 1rem;
              font-weight: 600;
              color: $pry-color;
              text-decoration: none;
            }
          }

          &__button {
            @include button;
            font-size: 16px;
            padding: 0.5rem 2rem;
          }
        }
      }
    }

    .topnav {
      background: linear-gradient(190deg, #e2d8ff 15.22%, #fee9ff 67.5%);
      text-align: center;

      p {
        color: $pry-color;
        font-weight: 600;
        padding: 0.5rem 0;

      }
    }

    .dropdown {
      // background: linear-gradient(to left, #f7f9f6d2, #f4f8f9e2, #f6f6f6cd) , url("https://img.freepik.com/free-vector/gradient-white-background-with-hexagons_79603-2164.jpg?t=st=1731848046~exp=1731851646~hmac=17db2cbcfea99c165c0ccb08e787eb94b0aa4f71b1387d208b7cef44f00be4da&w=826"); /* Gradient colors */
      filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2));
      backdrop-filter: blur(20px);
      position: absolute;
      left: 0;
      right: 0;
      background-color: white;
      top: 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      z-index: 2;
      padding: 1rem;
      // width: 100%; // Adjust width based on design
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      animation: slides 0.7s ease-in-out;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        // bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        /* Thickness of the border */
        background: linear-gradient(to left, #68ca48, #0082bf, #004676);
        /* Gradient colors */

      }

      &__box {

        & a {
          padding: 0px 20px;
          color: #5e5d5d;
          text-decoration: none;
          font-size: 0.9rem;
          border: 2px solid transparent;
          transition: all 0.6s ease inherit;


          &:hover {
            // background-color: $pry-color;
            border: 2px solid $pry-color;
            border-radius: 10px;

          }
        }

        &__item {
          padding: 1rem 0;

          h4 {

            // padding: 0.5rem 1rem;
            border-radius: 5px;
            margin-bottom: 1.3rem;
            color: $pry-color;
            display: inline;
            font-size: 18px;
          }

          &__label {
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 600;
            margin-top: 0.5rem;
            color: $black-color;
          }

          &__description {
            font-size: 0.85rem;
            font-weight: 400;
            color: #404040;
            line-height: 1.4;
          }
        }
      }

    }

    @keyframes slides {
      0% {
        top: 70%;
        opacity: 0;
      }

      100% {
        top: 100%;
        opacity: 1;

      }

    }
  }

  .mobile_nav {
    display: none;
  }

  .toggle_menu {
    display: none;
  }
}


@media screen and (max-width:993px) {
  header {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    animation: animed 0.6s ease-in-out;
    top: 0;
    background-color: $white-color;
    z-index: 6;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.39);
    border-bottom: 0.5px solid #00000055;
  }

  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  .mobile_nav {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    background-color: $white-color;
    z-index: 1111;
    border-bottom: 1px solid #00477634;


    .top_nav {
      background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
      padding: 0.5rem;
      @include flex(space-between, center, 0);

      &__contact {
        @include flex(flex-start, center, 1rem);
        // border-left: 1.5px solid $light-black-color;
        
        &__phoneIcon {
          // margin-left: 3rem;
          font-size: 1rem;
          color: $white-color;
          // background-color: #00477630;
          // padding: 10px;
          border-radius: 4px;
        }
        &__number {
          display: flex;
          flex-direction: column;

          span {
            font-family: 'Poppins', sans-serif;
            font-size: 0.5rem;
            color: #e8e8e8;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-size: 0.8rem;
            font-weight: 600;
            color: $white-color;
            text-decoration: none;
          }
        }

        &__button {
          // @include button;
          // background-color:$pry-color;
          // color: $pry-color;
          font-weight: 600;
          font-size: 0.6rem;
          padding: 0.5rem 1rem;
          border: none;
          border-radius: 5px;
          background-color: $white-color;
          // color: $white-color;

        }
      }
    }

    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;

      a {
        .logo__ {
          width: 40%;

          img {
            width: 100%;
          }
        }
      }


      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: #000;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-8px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(8px);
              width: 20px;
              left: 15px;
            }
          }
        }

        .menu-one {
          display: none;
        }
      }
    }
  }

  .toggle_menu {
    display: block;
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: $white-color;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;

    .menus {
      ul {
        padding: 0;

        .dropdown {
          padding: 0;
        }

        li {
          display: block;
          text-align: left;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px dotted rgb(199, 198, 198);

          span {
            font-size: 0.8em;
            padding-bottom: 1rem;
            border-bottom: 1px dotted rgb(199, 198, 198);
            width: 100%;
            display: block;
          }

          button {
            border: none;
            background-color: transparent;
            color: #000;
            font-weight: 600;
            font-size: 0.8em;
          }

          .dropdown-toggle {
            position: relative;

            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }

          a {
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0.50px;
            // font-family: "Kanit", sans-serif;  
            line-height: 2rem;
            text-decoration: none;
            color: #000;
            text-transform: capitalize;

            &.active {
              color: #000;
            }
          }

          ul {
            background-color: transparent;
            border: none;
            margin-top: 0.5rem;

            li {
              padding: 0;
              background-color: #fff;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 3rem;
    }

    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: transparet;
      height: auto;
      color: #000;
      padding: 2rem 2rem 0;
      text-align: center;

      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }

      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }

      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }

      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }

      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;

          a {
            color: #000;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}