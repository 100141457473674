*{
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6{
  margin:0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-optical-sizing: auto;
}
p{
  color: #3b3b3b
}
p,a,li,span{
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

ul{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}


.slick-dots li button:before {
    font-size: 10px;
    color: grey; /* Default color for inactive dots */
    opacity: 1; /* Set opacity to make the color more solid */
}

.slick-dots li.slick-active button:before {
    color: red; /* Color for the active dot */
}

/* Custom styling for the dots to look like lines */
.slick-dots li button {
    width: 20px;
    height: 3px;
    background: grey;
    border-radius: 2px;
    margin: 0 5px;
    opacity: 0.3;
    transition: opacity 0.3s;
}

.slick-dots li.slick-active button {
    background: #40bfb9;
    opacity: 1;
}

/* Hide the original dots icon */
.slick-dots li button:before {
    display: none;
}