@mixin button {
    position: relative;
    display: inline-block;
    color: $pry-color; // Set text color
    padding: 0.8rem 2.5rem;
    font-size: 1.1rem;
    border-radius: 50px; // Apply border radius
    background: none;
    z-index: 1;
    border: none;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: inherit; // Match the border radius
        padding: 4px; // Border thickness
        background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: -1;
    }

    &:hover::before {
        background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
        -webkit-mask:none
    }

    &:hover {
        color: $white-color; 
        background: transparent;
    }
}



@mixin flex ($justify, $align, $gap) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    gap: $gap
}

@mixin head{
    text-align: center;
        margin-bottom: 4rem;

        h2 {
            font-size: 2.2rem;
            margin-bottom: 2rem;
            font-weight: bold;
            z-index: 1;
            display: inline-block;
            position: relative;

            // &::after {
            //     background-color: $pry-color;
            //     position: absolute;
            //     // top: 0;
            //     right: -10px;
            //     bottom: -10px;
            //     width: 200px;
            //     height: 30px;
            //     content: "";
            //     z-index: -1;

            // }
        }

        p {
            font-size: 16px;
            font-weight: 500;
            margin: 0 10rem;
            line-height: 1.6;
            color: #777;
        }
}