@import "../config/veriabals.scss";
@import "../config/mixin.scss";
@import "../config/fuctions.scss";

.subservice {
    .heading {
        background: linear-gradient(86deg, rgba(221, 241, 247, 0.721) 34%, rgba(241, 254, 245, 0.703) 74%), url("../../../../public/assets/bg.webp");
        color: $black-color;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 10rem 0 5rem;

        h1 {
            font-size: 3.5rem;
            margin-bottom: 2rem;
            display: inline-block;
            z-index: 1;
            position: relative;
            color: $pry-color;
            
        }

        p {
            font-size: 16px;
            font-weight: 500;
            // margin: 0 3rem;
            line-height: 1.6;
            color: #4d4d4d;
            width: 70%;
        }
    }

    .whySection {
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        gap: 3rem;
        margin: 5rem 0;

        .textColumn {
            flex: 1;
            margin-right: 2rem;

            h2 {
                font-size: 2.5rem;
                margin-bottom: 1rem;
                font-weight: 500;
                line-height: 1.5rem*1.5;
                z-index: 1;
                color: $pry-color;
                position: relative;
                display: inline-block;

            }

            ul {
                list-style-type: none;
                padding-left: 0;
                margin-top: 2rem;

                li {
                    font-size: 0.9rem;
                    margin-bottom: 0.8rem;
                    font-weight: 500;

                }
            }
        }

        .imageColumn {
            img {
                width: 100%;
                height: 80vh;
                object-fit: cover;
                border-radius: 10px;
                border-top-left-radius: 50px;
                border-bottom-right-radius: 50px;
            }
        }
    }

    /* Use Cases Section */
    .useCases {
        margin-top: 4rem;
        margin-bottom: 6rem;

        .head {
            text-align: center;
            margin-bottom: 4rem;

            h6 {
                margin-bottom: 1rem;
                color: $pry-color;
                font-size: 16px;
            }

            h2 {
                font-size: 3rem;
                margin-bottom: 2rem;
                color: $pry-color;
                z-index: 1;
                display: inline-block;
                position: relative;
            }

            p {
                font-size: 18px;
                font-weight: 500;
                margin: 0 3rem;
                line-height: 1.6;
            }
        }

        h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        .useCaseList {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;
            text-align: left;


            .useCaseItem {
                box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
                border-radius: 10px;
                cursor: pointer;
                transition: all 0.6s ease-in-out;
                width: 350px;


                .imge {
                    position: relative;

                    img {
                        width: 100%;
                        height: 40vh;
                        object-fit: cover;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        /* Adjust the border thickness */
                        background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
                        /* Match the border curve of the image */
                        pointer-events: none;
                        /* Ensure it doesn’t block interactions */
                    }
                }


                .content {
                    padding: 1rem;
                }

                h3 {
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin-bottom: 1rem;
                    color: $pry-color;
                }

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: #464646;
                }
            }
        }
    }
}

@media screen and (max-width:1199px) {
    .subservice {
        .heading {
            background: linear-gradient(86deg, rgba(221, 241, 247, 0.721) 34%, rgba(241, 254, 245, 0.703) 74%), url("../../../../public/assets/bg.webp");
            color: $black-color;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 8rem 0 3rem;
    
            h1 {
                font-size: 2.5rem;
                margin-bottom: 2rem;
                display: inline-block;
                z-index: 1;
                position: relative;
                color: $pry-color;
                
            }
    
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.6;
                color: #4d4d4d;
                width: 70%;
            }
        }
    
        .whySection {
            display: grid;
            grid-template-columns:1.5fr 2fr;
            gap: 3rem;
            margin: 5rem 0;
    
            .textColumn {
                flex: 1;
                margin-right: 2rem;
    
                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    line-height: 1.5rem*1.5;
                    z-index: 1;
                    color: $pry-color;
                    position: relative;
                    display: inline-block;
    
                }
    
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin-top: 2rem;
    
                    li {
                        font-size: 0.8rem;
                        margin-bottom: 0.8rem;
                        font-weight: 500;
                        
                    }
                    img{
                        width: 30px;
                    }
                }
            }
    
            .imageColumn {
                img {
                    width: 100%;
                    height: 80vh;
                    object-fit: cover;
                    border-radius: 10px;
                    border-top-left-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
            }
        }
    
        /* Use Cases Section */
        .useCases {
            margin-top: 4rem;
            margin-bottom: 6rem;
    
            .head {
                text-align: center;
                margin-bottom: 2rem;
    
                h6 {
                    margin-bottom: 1rem;
                    color: $pry-color;
                    font-size: 16px;
                }
    
                h2 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    color: $pry-color;
                    z-index: 1;
                    display: inline-block;
                    position: relative;
                }
    
                p {
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 3rem;
                    line-height: 1.6;
                }
            }
    
            h2 {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
    
            .useCaseList {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 1rem;
                row-gap: 2rem;
                text-align: left;
    
    
                .useCaseItem {
                    box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.6s ease-in-out;
                    width: 260px;
    
    
                    .imge {
                        position: relative;
    
                        img {
                            width: 100%;
                            height: 40vh;
                            object-fit: cover;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
    
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 4px;
                            /* Adjust the border thickness */
                            background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
                            /* Match the border curve of the image */
                            pointer-events: none;
                            /* Ensure it doesn’t block interactions */
                        }
                    }
    
    
                    .content {
                        padding: 1rem;
                    }
    
                    h3 {
                        font-size: 1rem;
                        font-weight: 500;
                        margin-bottom: 0.5rem;
                        color: $pry-color;
                    }
    
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        color: #464646;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:424px) {
    .subservice {
        .heading {
            padding: 3rem 0 ;
    
            h1 {
                font-size: 26px;
                margin-bottom: 1rem;   
            }
    
            p {
                font-size: 12px;

            }
        }
    
        .whySection {
            display: grid;
            grid-template-columns:  1fr;
            gap: 2rem;
            margin: 3rem 0;
    
            .textColumn {
                flex: 0;
                margin-right: 0;
    
                h2 {
                    font-size: 20px;
                    margin-bottom: 0;
                    font-weight: 500;
                    line-height: 1.5rem*1.5;
                    z-index: 1;
                    color: $pry-color;
                    position: relative;
                    display: inline-block;
    
                }
    
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin-top: 2rem;
    
                    li {
                        font-size: 12px;
                        margin-bottom: 0.8rem;
                        font-weight: 500;
    
                    }
                    img{
                        width: 20px;
                    }
                }
            }
    
            .imageColumn {
                img {
                    width: 100%;
                    height: 50vh;
                    object-fit: cover;
                    border-radius: 10px;
                    border-top-left-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
            }
        }
    
        /* Use Cases Section */
        .useCases {
            margin-top: 4rem;
            margin-bottom: 6rem;
    
            .head {
                text-align: center;
                margin-bottom: 2rem;
    
                h6 {
                    margin-bottom: 1rem;
                    color: $pry-color;
                    font-size: 16px;
                }
    
                h2 {
                    font-size: 26px;
                    margin-bottom: 2rem;
                    color: $pry-color;
                    z-index: 1;
                    display: inline-block;
                    position: relative;

                }
    
                p {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0 ;
                    line-height: 1.6;
                }
            }
    
            h2 {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
    
            .useCaseList {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 2rem;
                text-align: left;
    
    
                .useCaseItem {
                    box-shadow: 4px 4px 15px 1px rgb(0 0 0 / 16%);
                    border-radius: 10px;
                    cursor: pointer;
                    transition: all 0.6s ease-in-out;
                    width: 350px;
    
    
                    .imge {
                        position: relative;
    
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }
    
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 4px;
                            /* Adjust the border thickness */
                            background: linear-gradient(to left, #68ca48 0%, #0082bf 46.77%, #004676 100%);
                            /* Match the border curve of the image */
                            pointer-events: none;
                            /* Ensure it doesn’t block interactions */
                        }
                    }
    
    
                    .content {
                        padding: 1rem;
                    }
    
                    h3 {
                        font-size: 1rem;
                        font-weight: 500;
                        margin-bottom: 1rem;
                        color: $pry-color;
                    }
    
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #464646;
                    }
                }
            }
        }
    }
}