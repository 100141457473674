@import "../config/mixin.scss";
@import "../config/veriabals.scss";
@import "../config/fuctions.scss";

.heroContact {
    padding: 0px 0 40px; // Padding for top and bottom
    display: flex;
    justify-content: center;

    &__wrp {
        background:linear-gradient(to left, #68ca482f 0%, #0083bf1f 46.77%, #00477624 100%);
        // background:#E2FFF8;// Light background color similar to the design
        @include flex (space-between, center, 3rem);
        width: 100%;
        // border-top-right-radius: 100px;
        // border-bottom-left-radius: 100px;
        border-radius: 10px;
        // box-shadow: $bg-shadow;

        &__imageSection {
            width: 100%;
            padding-left: 2rem;

            img {
                max-width: 100%; // Responsive image
                border-radius: 10px;
            }
        }

        &__textSection {
            h6 {
                font-size: 16px;
                font-weight: 500;
                color: $pry-color;
                margin-bottom: 1rem;
                display: none;
            }

            h2 {
                font-size: 1.9rem;
                margin-bottom: 15px;
                font-weight: 700;
                color: $pry-color; // Darker text color for heading
            }

            p {
                font-size: 1rem;
                color: #313131; // Slightly lighter text color for paragraph
                margin-bottom: 25px;
                font-weight: 500;
            }

            .contactButton {
                @include button;
                background: #ffffff;
                &:hover{
                    color: $white-color;
                }
            }
        }
    }

}


@media screen and (max-width:1199px) {
    .heroContact {
        padding: 0px 0 40px; // Padding for top and bottom
        display: flex;
        justify-content: center;
    
        &__wrp {
            background:linear-gradient(to left, #68ca482f 0%, #0083bf1f 46.77%, #00477624 100%);
            // background:#E2FFF8;// Light background color similar to the design
            @include flex (space-between, center, 3rem);
            width: 100%;
            // border-top-right-radius: 100px;
            // border-bottom-left-radius: 100px;
            border-radius: 10px;
            // box-shadow: $bg-shadow;
    
            &__imageSection {
                width: 100%;
                padding-left: 1rem;
    
                img {
                    max-width: 100%; // Responsive image
                    border-radius: 10px;
                }
            }
    
            &__textSection {
                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $pry-color;
                    margin-bottom: 1rem;
                    display: none;
                }
    
                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 15px;
                    font-weight: 600;
                    color: $pry-color; // Darker text color for heading
                }
    
                p {
                    font-size: 14px;
                    color: #3e3d3d; // Slightly lighter text color for paragraph
                    margin-bottom: 25px;
                    font-weight: 500;
                }
    
                .contactButton {
                    @include button;
                    font-size: 14px;
                    padding: 0.5rem 2rem;
                    background: #ffffff;
                    &:hover{
                        color: $white-color;
                    }
                }
            }
        }
    
    }
}


@media screen and (max-width:424px) {
    .heroContact {
        padding: 0px 0 40px; // Padding for top and bottom
        display: flex;
        justify-content: center;
    
        &__wrp {
            background:linear-gradient(to bottom, #68ca482f 0%, #0083bf1f 46.77%, #00477624 100%);
            @include flex (space-between, center, 1rem);
            flex-direction: column;
            width: 100%;
            // border-top-right-radius: 100px;
            // border-bottom-left-radius: 100px;
            border-radius: 10px;
            // box-shadow: $bg-shadow;
    
            &__imageSection {
                width: 100%;
                padding-left: 0;  
    
                img {
                    max-width: 100%; // Responsive image
                    border-radius: 10px;
                }
            }
    
            &__textSection {
                h6 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $pry-color;
                    margin-bottom: 1rem;
                    display: none;
                }
    
                h2 {
                    font-size: 1rem;
                    margin-bottom: 15px;
                    font-weight: 600;
                    color: $pry-color; // Darker text color for heading
                }
    
                p {
                    font-size: 12px;
                    color: #313131; // Slightly lighter text color for paragraph
                    margin-bottom: 25px;
                    font-weight: 500;
                }
    
                .contactButton {
                    @include button;
                    padding: 0.5rem 1rem;
                    font-size: 14px;
                    background: #ffffff;
                    &:hover{
                        color: $white-color;
                    }
                }
            }
        }
    
    }
}