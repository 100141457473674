@import "../config/mixin.scss";
@import "../config/veriabals.scss";
@import "../config/fuctions.scss";

.footer {
    // background-color: #6ea7fc0e;
    padding: 40px 0 0;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .footerSection {
        flex-basis: 23%;
        margin-bottom: 20px;

        p{
            font-size: 14px;
            font-weight: 500;
            color: $black-color;
            margin: 1.5rem 0 1rem ;
        }
       strong{
        color: $black-color;
       }
        h3 {
            font-size: 1.1rem;
            margin-bottom: 2rem;
            color: $black-color;
            font-weight: 600;
            margin-top: 0.5rem;
            position: relative;
            display: inline-block;

            &::after{
                position: absolute;
                // top: 0;
                right: 0;
                left: 0;
                bottom: -10px;
                width: auto;
                height: 4px;
                content: "";
                border-radius: 10px;
                background-color: $pry-color;
            }
        }

        ul {
            list-style: none;
            padding: 0;


            li {
                margin-bottom: 8px;
                color: #777;
                font-size: 0.9rem;
                font-weight: 500;
                a {
                    color: #777;
                    font-size: 0.9rem;
                    font-weight: 500;
                    text-decoration: none;
                    transition: color 0.3s;

                    &:hover {
                        color: $pry-color;
                    }
                }
            }
        }
    }

    .logo {
        img{
            width: 80%;
        }
        span {
            font-weight: 700;
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
    }

    .socialLinks {
        margin-top: 1.5rem;
        @include flex(none, center, 10px);

        a {
            font-size: 1rem;
            margin-right: 15px;
            background-color: #40bfb928;
            padding: 5px 10px;
            border-radius: 5px;
            color: $pry-color;
            transition: all 0.6s ease-in-out;
            &:hover {
                color: #ffffff;
                background-color: $pry-color;
                transform: scale(1.2);

            }
        }
    }

    .footerBottom {
        text-align: center;
        margin-top: 20px;
        padding: 10px 0;
        // border-top: 1px solid #dddddd33;
        // background-color: $pry-color;
        background:linear-gradient(to left, #68ca48 0%, #0083bf 46.77%, #004776 100%);


        p {
            font-size: 0.85rem;
            color: #ffffff;
            font-weight: 500;
        }
    }
}


@media screen and (max-width:424px) {
    .footer {
        // background-color: #6ea7fc0e;
        padding: 40px 0 0;
        .container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 0.5rem;
            flex-wrap: nowrap;
            flex-direction: column;
            justify-content: space-between;
        }
    
        .footerSection {
            flex-basis: 23%;
            margin-bottom: 20px;
    
            p{
                font-size: 12px;
                font-weight: 500;
                color: $black-color;
                margin: 1.5rem 0 1rem ;
            }
           strong{
            color: $black-color;
            font-size: 14px;
           }
            h3 {
                font-size: 0.8rem;
                margin-bottom: 1rem;
                color: $black-color;
                font-weight: 600;
                margin-top: 0.5rem;
                position: relative;
                display: inline-block;
    
                &::after{
                    position: absolute;
                    // top: 0;
                    right: 0;
                    left: 0;
                    bottom: -10px;
                    width: auto;
                    height: 4px;
                    content: "";
                    border-radius: 10px;
                    background-color: $pry-color;
                }
            }
    
            ul {
                list-style: none;
                padding: 0;
    
    
                li {
                    margin-bottom: 1px;
                    color: #777;
                    font-size: 0.9rem;
                    font-weight: 500;
                    a {
                        color: #777;
                        font-size: 12px;
                        font-weight: 500;
                        text-decoration: none;
                        transition: color 0.3s;
    
                        &:hover {
                            color: $pry-color;
                        }
                    }
                }
            }
        }
    
        .logo {
            img{
                width: 40%;
            }
            span {
                font-weight: 700;
                font-size: 1.5rem;
                margin-bottom: 2rem;
            }
        }
    
        .socialLinks {
            margin-top: 1.5rem;
            @include flex(none, center, 10px);
    
            a {
                font-size: 1rem;
                margin-right: 15px;
                background-color: #40bfb928;
                padding: 2px 5px;
                border-radius: 5px;
                color: $pry-color;
                transition: all 0.6s ease-in-out;
                &:hover {
                    color: #ffffff;
                    background-color: $pry-color;
                    transform: scale(1.2);
    
                }
            }
        }
    
        .footerBottom {
            text-align: center;
            margin-top: 20px;
            padding: 10px 0;
            // border-top: 1px solid #dddddd33;
            // background-color: $pry-color;
            background:linear-gradient(to left, #68ca48 0%, #0083bf 46.77%, #004776 100%);
    
    
            p {
                font-size: 0.85rem;
                color: #ffffff;
                font-weight: 500;
            }
        }
    }
}

